import styled from '@emotion/styled';
import CalendarIcon from '../../../images/calendar.svg';

import { ReactComponent as EyeIcon } from '../../../images/eye.svg';
import { ReactComponent as WarnIcon } from '../../../images/warning.svg';
import { ReactComponent as IncIcon } from '../../../images/inc.svg';
import { ReactComponent as DecIcon } from '../../../images/dec.svg';
import DownIcon from '../../../images/arrow/down.svg';
export const BagsBox = styled.form`
border-radius: 16px;
padding: 16px;
padding-bottom: 16px;
min-width: 1024px;
width: 100%;
height: fit-content;
background: var(--colors-grey-50);
  /* @media screen and (min-width: 375px) {
    width: 375px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
    display: flex;
    max-height: 100vh;
  } */
`;



export const BagsTitleBox = styled.div`
  display: flex;
align-items: flex-start;
justify-content: space-between;
border-bottom: 1px solid var(--colors-grey-200);
padding: 0px 0 12px 0px;

width:100%;


`;

export const BagsBodyBox = styled.div`
width: 100%;
height: 92%;
padding: 16px 0 0 0;
/* overflow-y: auto; */
`;


export const BagsTitle = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
color: var(--colors-grey-800);
`;


export const IconEye = styled(EyeIcon)`
  width: 22px;
  height: 16px;
  font-size: 24px;
  stroke: currentColor;
  margin-right: 12px;
  color: var(--colors-grey-25);
`;

export const IconWarn = styled(WarnIcon)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  stroke: currentColor;
  margin-right: 12px;
  color: var(--colors-grey-25);
  stroke:var(--colors-primary-600)
`;
export const TextCreation = styled.p`
display: flex;
align-items: center;
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-500);
`;

export const IconInc = styled(IncIcon)`
  width: 16px;
  height: 17px;
  font-size: 24px;
  stroke:  var(--colors-secondary-500);
 
  color: var(--colors-secondary-500);
`;

export const IconDec = styled(DecIcon)`
  width: 16px;
  height: 3px;
  font-size: 24px;


  stroke:${props => props.disabled === 'true' ? 'var(--colors-secondary-500)' : 'var(--colors-grey-300)'} ;

  color: var(--colors-grey-300);
`;

export const BagsNoText = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-500);
`;


export const ButtonWrap = styled.div`

display: flex;
align-items: center;
gap:8px;
`;
export const PreviewButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
display: flex;
align-items: center;
color: var(--colors-primary-600);
background:var(--colors-grey-25); 
border: 1px solid var(--colors-primary-600);
border-radius: 360px;
padding: 8px 24px;
cursor: pointer;
`;



export const SaleButton = styled.button`

font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
display: flex;
align-items: center;
color: var(--colors-grey-25);
background: ${props=>props.disabled?'var(--colors-grey-300)':'var(--colors-primary-600)'};
border-radius: 360px;
padding: 8px 24px;
cursor: pointer;
cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Fields = styled.ul`
display: flex;
flex-wrap: wrap;
gap:16px;

`;
// Загальний стиль для всіх полів кошика

export const FieldWrapper = styled.li`
border-radius: 16px;
background: var(--colors-grey-25);
padding: 16px;
height: fit-content;
border: ${props => props.errors ? "1px solid red" : "none"};
width: calc((100% - 16px) / 2);
&:nth-of-type(1){
   
    display: flex;
    flex-direction: column;
 
    height: 105px;
  }
  &:nth-of-type(2){

    display: flex;
    align-items: center;
    justify-content:space-between;
    height: 105px;
  }
 
  &:nth-of-type(3){
    padding: 0;
    width: 100%;
    background: none;
    display: flex;
    flex-direction: column;
    gap:16px;
    height: auto;
   
  }
  &:nth-of-type(4){
    width: 100%;
  }
  &:nth-of-type(5){
 
  }
  &:nth-of-type(6){
  
  }
  &:nth-of-type(7){
   padding: 16px;

    height: fit-content;
  }
  &:nth-of-type(8){
   
   height: fit-content;
  }


  &:nth-of-type(9){
    width: 100%;
  }
  &:nth-of-type(10){
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }


 
`;
export const SubFieldWrapper = styled.div`
border-radius: 16px;
background: var(--colors-grey-25);
padding: 16px;
height: fit-content;
width: 100%;
border: ${props => props.errors ? "1px solid red" : "none"};
`;
export const SubFieldWrapper1 = styled.div`
border-radius: 16px;
background: var(--colors-grey-25);
padding: 16px;
height: 100%;
width: calc((100% - 16px) / 2);
border: ${props => props.errors ? "1px solid red" : "none"};
`;
export const SubFieldWrapper2 = styled.div`
border-radius: 16px;
background: var(--colors-grey-25);
padding: 16px;
height: 100%;
width: 100%;
min-height:191px ;
border: ${props => props.errors ? "1px solid red" : "none"};
`;

export const SubFieldWrapper3 = styled.div`
border-radius: 16px;
background: var(--colors-grey-25);
padding: 16px;
height: 100%;
width: 100%;
min-height:99px ;
`;
export const MainBox = styled.div`
display: flex;
gap: 48px;

`;
export const VerticalBox = styled.div`
  display: flex;
  flex-direction:column;
  gap: 8px;
  width: calc((100% - 16px) / 2);
`;

export const VerticalBox1 = styled.div`
  display: flex;
  flex-direction:column;
  gap: 16px;
  width: calc((100% - 16px) / 2);
`;
export const HorizontalBox = styled.div`
display: flex;
align-items: center;
gap: 12px;
`;

export const HorizontalBox1 = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 12px;

`;

// Стиль для міток
export const Label = styled.label`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
margin-bottom:4px;
color: var(--colors-grey-500);
box-sizing: border-box;
display:inline-block;
`;
// Стиль для міток
export const Label0 = styled.label`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;

color: var(--colors-grey-500);
`;

export const Label1 = styled.label`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-900);
display: flex;
gap:4px;
`;

export const Label4 = styled.label`
display: inline-block;
font-family: var(--font-family);
width: 100%;
font-weight: 500;
font-size: 14px;
line-height: 150%;
margin-bottom:4px;
color: var(--colors-grey-500);
position: relative;

`;

export const InputTime = styled.input`
-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  text-align: center;
  height:48px;
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
outline:none;
  &::placeholder{
    color: var(--colors-grey-400);
  }
`;

// Стиль для інпутів
export const Input = styled.input`
  width: 100%;
  
  height:48px;
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
margin-bottom: 15px;
color: var(--colors-grey-900);
outline:none;
  &::placeholder{
    color: var(--colors-grey-400);
  }
`;







// Стиль для textarea
export const TextArea = styled.textarea`
appearance: none;
  width: 100%;
  border-radius: 16px!important;
padding: 12px 16px;
border: 1px solid var(--colors-grey-100);
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
  border-radius: 4px;
  min-height: 220px;
  height: calc( 100% - 53px);
  resize: none;
  outline:none;
  margin-bottom: 8px;
`;

// Стиль для кнопок
export const CountLetter = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
width: 100%;
color: var(--colors-grey-600);

 
  text-align: right;
`;



// Стиль для кнопок
export const Button = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;




// Стиль для контейнера для перетягування фото


export const Categories = styled.ul`
  width: 100%;
display: flex;
flex-wrap:wrap;
gap:8px;
`;

export const Categorie = styled.ul`
  width: auto;
  border-radius: 360px;
  cursor: pointer;
  padding: 4px 12px;
  display:flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  border: 1px solid var(--colors-grey-50);
  background: var(--colors-grey-50);
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-700);
&.selected{
  border: 1px solid var(--colors-secondary-300);
  background: var(--colors-secondary-50);
  color: var(--colors-secondary-600);
}
`;

// Стиль для селектів
export const Select = styled.select`
  width: 100%;
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
background: var(--colors-grey-25);
outline: none;
appearance: none;
position: relative; 

`;

// Стиль для чекбоксів та радіобатонів
export const CheckBox = styled.input`
  margin-right: 10px;
  width: 20px;
  height: 20px;
`;

export const RadioButton = styled.input`
  margin-right: 10px;
  width: 16px;
  height: 16px;
  background-color: white;
`;

export const QuantityInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 4px 12px;
width: 104px;
height: 48px;
`;

export const QuantityButton = styled.button`
padding: 0;
  height: 100%;
  width:auto;
  display: flex;
  align-items: center;
  justify-content: center;
 background:none;
  cursor: pointer;
 
`;
export const InputCount = styled.input`
  width: 100%;
  padding: 0;
  width: 27px;
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-900);
  border: none
  
`;



export const ExpandableSection = styled.div`
  border-top: 1px solid var(--colors-grey-100);
margin-top: 12px;
  padding-top: 8px;
  width: 100%;
`;

export const RepeatSchedule = styled.div`
  display: flex;
  gap: 48px;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0px 0px 12px 0px;
  border-bottom: 1px solid var(--colors-grey-100);

`;

export const RadioGroup = styled.div`
width: 49%;
  display: flex;
  flex-direction: column;
`;

export const RadioInputGroup = styled.label`
  display: flex;
  gap:12px ;
  align-items: center;
  font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
position:relative;
color: var(--colors-grey-900);
  &+&{
    margin-top: 8px;
  }
 
`;





export const DayCheckboxGroup = styled.div`
  display: flex;
 
  flex-wrap: wrap;
  gap: 8px;
  min-height: 108px;
  width: 100%;
`;

export const DayCheckbox = styled.div`
width: calc((100% - 25px) / 2);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
`;

export const DatePickerGroup = styled.div`
  display: flex;
 width: auto;
  gap: 16px;
  margin-top: 16px;
  gap:48px;
`;

export const DateRadioGroup = styled.div`
width: 49%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NumberInput = styled(Input)`
  width: 80px;
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
width: 80px;
height: 48px;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-900);
`;

export const InputLabel = styled(Label)`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-700);
margin-bottom: 12px;
`;

export const InputLabel1 = styled(Label)`
display: flex;
gap: 13.5px;
align-items: center;
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-700);
margin-bottom: 12px;
position:relative;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background:none;
    border: 1px solid var(--colors-grey-400);
    border-radius: 2px;
  }

  input:checked + .checkmark {
    background-color: var(--colors-secondary-500);
    border: 1px solid var(--colors-secondary-500);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked + .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 10%;
    left: 30%;
   
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const RadioWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .radioMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color:  none;
    border-radius: 100%;
    border: 1px solid var(--colors-grey-400);
  }

  input:checked + .radioMark {
    background-color: none;
    border: 1px solid  var(--colors-secondary-500);

  }

  .radioMark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked + .radioMark:after {
    display: block;

  }

  .radioMark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background:  var(--colors-secondary-500);
  }
`;


export const CustomInput = styled.input`
z-index:2;
  width: 100%;
  text-align: center;
  height:48px;
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
padding-right: 40px;
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
outline:none;
position: relative;
&:focus{
  border: 1px solid var(--colors-grey-100);
}
  &::placeholder{
    color: var(--colors-grey-400);
  }
  background-color: inherit;
  &.open-calendar {
     border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom:none ;

  }
`;  