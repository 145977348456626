import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const pricesApi = createApi({
  reducerPath: 'prices',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: false,
  keepUnusedDataFor: 1,

  endpoints: (builder) => ({
    
    // GET: Fetch prices
    getPrices: builder.query({
      query: () => '/dictionaries/bag-prices',
      providesTags: ['Price'],
    }),

    // POST: Creating a new price
    createPrice: builder.mutation({
      query: (newPrice) => ({
        url: '/prices/create',
        method: 'POST',
        body: newPrice,
      }),
      invalidatesTags: ['Price'],
    }),

    // POST: Updating an existing price
    updatePrice: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `/prices/update?id=${id}`,
        method: 'POST',
        body: updateData,
      }),
      invalidatesTags: ['Price'],
    }),

    // POST: Deleting a price
    deletePrice: builder.mutation({
      query: (id) => ({
        url: `/prices/delete?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Price'],
    }),
  }),
});

export const {
  useGetPricesQuery,
  useCreatePriceMutation,
  useUpdatePriceMutation,
  useDeletePriceMutation,
} = pricesApi;