import { createPortal } from 'react-dom';
import {

  ModalContent,
  ModalOverlay,
  CloseBtn,
} from './ModalMessage.styled';



const ModalMessage = ({ children, onClose, pad }) => {
  const handleContentClick = event => {
    event.stopPropagation();
  };

  return <ModalOverlay onClick={onClose}>
    <ModalContent pad={pad} onClick={handleContentClick}>

      {pad !== "false" && <CloseBtn onClick={onClose} />}

      {children}
    </ModalContent>
  </ModalOverlay >


};

export default ModalMessage;
