import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Suspense, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectIsOnBoard, selectUser } from '../../../redux/auth/selectors';
import { getCurrentUser } from '../../../redux/auth/operations';

import {
  Container,

  Main,
  Wrapper,
  PageWrapper,
} from './Layout.styled';

import Header from '../Header/Header';
import SideBar from '../SideBar';
// import Spinner from '../Spiner';

import ImageAnimation1 from '../../../components/layout/loader/ImageAnimation1';

import MerchantBill from 'components/modal/MerchantBill/MerchantBill';



const Layout = () => {

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const isOnBoard = useSelector(selectIsOnBoard);

  const [searchParams, setSearchParams] = useSearchParams();
  const onboard = searchParams.get('onboard');

  console.log('onboard :>> ', onboard);
  const [isModalOpenRax, setModalOpenRax] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState('start');
  const [isModalBoardOpen, setModalBoardOpen] = useState(false);

  const location = useLocation();

  const arrays = location.pathname.split('/');

  const currentPage = arrays[arrays.length - 1];





  const handleOpenModalRax = () => {
    setModalOpenRax(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModalRax = () => {
    setModalOpenRax(false);
    document.body.style.overflow = 'auto';

  };



  useEffect(() => {
    if (!user) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, user]);



  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModalRax();

      }
    };

    if (isModalOpenRax) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpenRax]);

  return (
    <Container>


      <Wrapper>
        <SideBar onboard={onboard} />
        <PageWrapper>
          <Header />
          <Main>
            <Suspense fallback={<ImageAnimation1 />}>
              <Outlet />
            </Suspense>
            
       
         

            {
              isModalOpenRax && (
                <MerchantBill onClose={handleCloseModalRax} merchant={user?.merchant} />
              )
            }
          </Main>
        </PageWrapper>
      </Wrapper>


    </Container>
  );
};

export default Layout;
