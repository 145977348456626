import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as StatIcon } from '../../../images/bars.svg';
import { ReactComponent as CommIcon } from '../../../images/checkMes.svg';
import { ReactComponent as DocsIcon } from '../../../images/7.svg';
import { ReactComponent as UserIcon } from '../../../images/user.svg';
import { ReactComponent as ServiceIcon } from '../../../images/Services.svg';
import { ReactComponent as FinanceIcon } from '../../../images/Finance.svg';
import { ReactComponent as SettingsIcon } from '../../../images/gear.svg';
export const Container = styled.div`
height: 100vh;
background: var(--colors-grey-50);
padding: 24px 16px;
border-right: 2px solid var(--colors-primary-600);
width: 100%;
display: flex;
flex-direction: column;
/* justify-content: space-between; */
gap: 64px;
`;

export const TitleBox = styled.div`
display: flex;
gap:8px ;
width: 100%;
height: auto;
align-items: center;

color: var(--colors-grey-900);
border: 1px solid var(--colors-grey-200);

border-radius: 16px;
/* margin-bottom: 80px; */
background: var(--colors-grey-25);


`;



export const TitleName = styled.p`

padding: 8px;
width:calc(100% - 64px);
height: auto;
align-items: center;
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
white-space:nowrap;
text-overflow: ellipsis;
overflow: hidden;

/* margin-bottom: 80px; */



`;

export const ImgTitle = styled.img`

border-radius: 16px;
width: 56px;
height:56px;

`;

export const PreImg = styled.span`
border: 1px solid var(--colors-grey-300);
border-radius: 16px;
display: flex;
font-size: 32px;
justify-content: center;
align-items: center;
background: var(--colors-primary-600);
color: var(--colors-grey-50);
width: 56px;
height:56px;

`;


export const Text = styled.p`
color: var(--colors-grey-900);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 24px;
font-family: var(--font-family);
  @media screen and (min-width: 768px) {
    font-size: 14px;
    margin-bottom: 32px;
  }
`;

export const LinkNav = styled(Link)`
  display: flex;
  align-items: center;
gap: 12px;
  text-decoration: none;
  width: 100%;
  height: 100%;
  color: var(--colors-primary-600);
  font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
  padding: 8px 12px;
overflow:hidden;
text-wrap:nowrap;
  /* @media screen and (min-width: 768px) {
    font-size: 16px;
    padding: 16px 20px
  } */
`;

export const IconFinance = styled(FinanceIcon)`
 width: 32px;
  height: 32px;
  font-size: 24px;
  stroke: currentColor;
   margin-right: 0px;
  &.hovered{
  margin-right: 8px;
}

  color: ${props =>
    props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};

  /* @media screen and (min-width: 768px) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  } */
`;

export const IconUser = styled(UserIcon)`
  width: 32px;
  height: 32px;
  font-size: 24px;
  stroke:${props => props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};
  margin-right: 0px;
 
  &.hovered{
  margin-right: 8px;
}
  color: ${props =>
    props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};

  /* @media screen and (min-width: 768px) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  } */
`;

export const IconDocs = styled(DocsIcon)`
  width: 32px;
  height: 32px;
  font-size: 24px;
  fill:${props => props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};
  margin-right: 0px;
 
  &.hovered{
  margin-right: 8px;
}
  color: ${props =>
    props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};

  /* @media screen and (min-width: 768px) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  } */
`;



export const IconStatistics = styled(StatIcon)`
 width: 32px;
  height: 32px;
  font-size: 24px;
   margin-right: 0px;
  &.hovered{
  margin-right: 8px;
}
  fill: currentColor;
  stroke: currentColor;
  color: ${props =>
    props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};

  /* @media screen and (min-width: 768px) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  } */
`;


export const IconComments = styled(CommIcon)`
 width: 32px;
  height: 32px;
  font-size: 24px;
   margin-right: 0px;
  &.hovered{
  margin-right: 8px;
}
  stroke: currentColor;

  color: ${props =>
    props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};

  /* @media screen and (min-width: 768px) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  } */
`;



export const IconServices = styled(ServiceIcon)`
width: 32px;
  height: 32px;
  font-size: 24px;
   margin-right: 0px;
  &.hovered{
  margin-right: 8px;
}
  color: ${props =>
    props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};
  fill: currentColor;
  /* @media screen and (min-width: 768px) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  } */
`;

export const IconSettings = styled(SettingsIcon)`
  width: 32px;
  height: 32px;
  stroke: currentColor;
  font-size: 24px;
  margin-right: 0px;
  &.hovered{
  margin-right: 8px;
}
  color: ${props =>
    props.isactive ? 'var(--colors-grey-25)' : "var(--colors-primary-600)"};

  /* @media screen and (min-width: 768px) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  } */
`;

export const List = styled.ul`
  display: grid;
  gap: 4px;

  /* @media screen and (min-width: 768px) {
    gap: 16px;
  } */
`;

export const ListItem = styled.li`
  width: 56px;
  height: 56px;
 border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content:flex-start ;
  transition: width 0.3s ease;
    &.hovered{
      width:225px;
        transition: width 0.3s ease;
    }

& a:focus{
   border-radius: 16px;
   outline:none;
  
  border: 1px solid var(--colors-primary-600);
}

  /* @media screen and (min-width: 768px) {
    width: 225px;
    height: 56px;
  }

  @media screen and (min-width: 1440px) {
    width: 241px;
  } */

  &:hover a {
 /* background: var(--colors-primary-600); */
 border: none;
 background: ${props => props.none ? 'none' : 'var(--colors-grey-200)'};
   border-radius: 16px;
  }
  &:hover .icon,
  &:hover .text {
     color:  var(--colors-primary-600);
  }

  &:focus a {
   border: 1px solid var(--colors-primary-600);
 background:none;
  border-radius: 16px;
  }


&:focus .icon, &:focus  .text {
      color:  var(--colors-primary-600);
  }

  &:active a {
   border: none;
 background: ${props => props.none ? 'none' : 'var(--colors-primary-200)'};
  border-radius: 16px;
  }

 &:active a:focus {
   border: none;
outline:none;
  }

  &.active a{
      border-radius: 16px;
    background-color: var(--colors-primary-600);
  }


  &.active .icon,
  &.active .text {
    color: var(--colors-grey-25);
  }

`;
export const ListBox = styled.nav`
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
height: 80%;

`;