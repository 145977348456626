import AddDoc from 'components/docs/AddDoc';






const DocsEditPage = () => {
  return <AddDoc />;
};

export default DocsEditPage;
