
import { useNavigate } from "react-router-dom";
import { Icon, NavDesc1, NavDesc, NavElement, NavItem, NavList, NavTitle, NavMod, IconEdit, IconTrash } from "./ListTypeQuest.styled";
import { useEffect, useState } from "react";
import AddFaq from "components/modal/AddFaq/AddFaq";




const ListTypeQuest = ({ faqData }) => {

  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
const [selectedTheme, setSelectedTheme]=useState({});
const [type,setType]=useState('');
const [titles,setTitles]=useState('');
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);
  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';

  };



  const handleClick = (id) => {
    navigate(`?category=${id}`);
  };
  const handleEventClick = (data) => {
   
    setSelectedTheme(data)
    setModalOpen(true);

  };
  const handleEditClick = (event, { id, title, image, description }) => {
    event.stopPropagation(); // Зупиняє поширення події
    setType('edit');
    setTitles('Редагувати тему');
    handleEventClick({ id, title, image, description });
  };
  
  const handleDeleteClick = (event, { id, title, image, description }) => {
    event.stopPropagation(); // Зупиняє поширення події
    setType('del');
    setTitles('Видалити тему');
    handleEventClick({ id, title, image, description });
  };


  return (


    <NavList>
      {
        faqData?.map(({ id, title, description, image, items }) => (<NavItem key={id}>
          
          <NavElement onClick={() => handleClick(id)}>
            <Icon src={image} alt={title} />
            <NavTitle>{title}</NavTitle>
            <NavDesc>{description}</NavDesc>
            <IconEdit onClick={(event) => handleEditClick(event, { id, title, image, description })} />
<IconTrash onClick={(event) => handleDeleteClick(event, { id, title, image, description })} />
          </NavElement>
        </NavItem>

        ))
      }
        {isModalOpen && (
          <AddFaq onClose={handleCloseModal} title={titles} types={type} theme={selectedTheme} />
        )}
      <NavItem>
        <NavDesc1>Не знайли відповідь на своє питання?</NavDesc1>

        <NavMod onClick={() => { setModalOpen(true) }}>Зв’язатись з нами</NavMod>

      </NavItem>

    </NavList>

  );
};

export default ListTypeQuest;
