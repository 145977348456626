
import { useEffect, useState } from "react";
import { WrapQuest, QuestList, QuestItem, IconArrowDown, QuestTitle, QuestDesc, IconEdit, IconTrash } from "./QuestionList.styled";
import AddFaqItem from "components/modal/AddFaqItem/AddFaqItem";


const QuestionList = ({ questions,isModalOpen,setModalOpen,handleCloseModal,types,title,setTypes,setTitle,categoryId  }) => {
  const [expandedIds, setExpandedIds] = useState([]);
 

const [selectedThemeItem, setSelectedThemeItem]=useState({});




  // Обробник кліку, що розгортає або згортає відповідь
  const toggleQuestion = (id) => {
    setExpandedIds(prevIds =>
      prevIds.includes(id)
        ? prevIds.filter(expandedId => expandedId !== id) // Якщо id вже є в масиві, видаляємо його (згортання)
        : [...prevIds, id] // Інакше додаємо id в масив (розгортання)
    );
  };

  const handleEventClick = (data) => {
   
    setSelectedThemeItem(data)
    setModalOpen(true);

  };
  const handleEditClick = (event, { id, title, description }) => {
    event.stopPropagation(); // Зупиняє поширення події
    setTypes('edit');
    setTitle('Редагувати питання');
    handleEventClick({ id, title, description });
  };
  
  const handleDeleteClick = (event, { id, title,  description }) => {
    event.stopPropagation(); // Зупиняє поширення події
    setTypes('del');
    setTitle('Видалити питання');
    handleEventClick({ id, title,  description });
  };


  return (

    <QuestList>
      {
        questions?.map(({ id, title, description, }) => 
        (<QuestItem key={id}>
          <WrapQuest onClick={() => toggleQuestion(id)}>
            <QuestTitle>{title}</QuestTitle>
            <IconArrowDown up={expandedIds.includes(id) ? "true" : "false"} />

          </WrapQuest>
          <QuestDesc expanded={expandedIds.includes(id)}>
            {description}
          </QuestDesc>
          <IconEdit onClick={(event) => handleEditClick(event, { id, title,  description })} />
          <IconTrash onClick={(event) => handleDeleteClick(event, { id, title,  description })} />
        </QuestItem>

        ))
      }
 {isModalOpen && (
          <AddFaqItem categoryId ={categoryId }onClose={handleCloseModal} title={title} types={types} themeItem={selectedThemeItem} />
        )}
    </QuestList>


  );
};

export default QuestionList;
