import { useState, useEffect } from 'react';

import { Containerlogo, Loader, LoaderBar, LoaderBarContainer } from './ImageAnimation1.styled';

const ImageAnimation1 = () => {


  return (
    <Containerlogo>

      <Loader>
        <LoaderBarContainer>
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
        </LoaderBarContainer>
      </Loader>
    </Containerlogo>
  );
};
export default ImageAnimation1;