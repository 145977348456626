import styled from '@emotion/styled';
import { ReactComponent as PlusIcon } from '../../../images/Plus.svg';
import { ReactComponent as DeliveryIcon } from '../../../images/delivery_box.svg';
import { ReactComponent as ArrowDown } from '../../../images/arrow/down.svg';

import { Link } from 'react-router-dom';

export const BagsBox = styled.div`
border-radius: 16px;
padding: 16px;
width: 100%;
background: var(--colors-grey-50);
  /* @media screen and (min-width: 375px) {
    width: 375px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
    display: flex;
    max-height: 100vh;
  } */
`;



export const BagsTitleBox = styled.div`
  display: flex;
align-items: flex-start;
justify-content: space-between;
border-bottom: 1px solid var(--colors-grey-200);
padding: 0px 0px 12px 0px;
width:100%;


`;

export const BagsBodyBox = styled.div`
width: 100%;
height: auto;
padding: 18px 0;
`;


export const BagsTitle = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
color: var(--colors-grey-800);
`;


export const IconPlus = styled(PlusIcon)`
  width: 18px;
  height: 18px;
  font-size: 24px;
  stroke: currentColor;
  margin-right: 12px;
  color: var(--colors-grey-25);

 
`;


export const IconDelivery = styled(DeliveryIcon)`
  width: 24px;
  height: 24px;

  stroke: currentColor;
 


 
`;

export const BagsNoText = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-500);
`;




export const ArchiveLink = styled(Link)`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-primary-600);
display: flex;
gap: 8px;
align-items: center;
justify-content: center;
margin: 0 auto;
margin-top: 16px;
`;

export const AddBagButton = styled(Link)`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
display: flex;
align-items: center;
color: var(--colors-grey-25);
background: var(--colors-primary-600);
border-radius: 360px;
padding: 8px 24px;
cursor: pointer;
`;
export const FilterBox = styled.div`
display: flex; 
align-items: center;
gap: 8px;

`;
export const Label = styled.label`
font-family: var(--font-family);
font-weight: 600;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-800);
margin-bottom: 8px;
box-sizing: border-box;
display:inline-block;
margin-bottom: 8px;
`;

export const Label1 = styled.label`

position: relative;
  display: inline-block;
`;

export const IconArrowDown = styled(ArrowDown)`
  width: 14px;
  height: 8px;
     content: '';
    position: absolute;
    top: 45%;
    right: 15px;



    pointer-events: none;
  transform: translateY(-20%) ${({ up }) => (up ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease-in-out;

`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const CustomSelect = styled.select`
  width: 240px;
  height: 48px;
   padding-right: 32px; /* Відступ для розміщення стрілки справа */
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
  color: var(--colors-grey-800);
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
background: var(--colors-grey-25);
outline: none;
appearance: none;
position: relative;
/* &.openSelect{
  border-radius: 16px;
   border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
} */
&.placeholder{

color: var(--colors-grey-400);
};


`;

