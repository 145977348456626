import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const ListNav = styled.ul`
  display: flex;
  background: var(--colors-grey-200);
  border-radius: 360px;
width:100%;
min-width: 1024px;
margin-bottom: 24px;

  /* @media screen and (min-width: 375px) {
    width: 375px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
    display: flex;
    max-height: 100vh;
  } */
`;


export const ListNavItem = styled.li`
  display: flex;
align-items: center;
justify-content: center;
height: 40px;
width: 50%;
border-radius: 360px;
`;


export const LinkNav = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 360px;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-500);
padding: 4px 8px;
&.active{
  background: var(--colors-primary-600);
  color: var(--colors-grey-25);
}

`;

