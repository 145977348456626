import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const settingsApi = createApi({
  reducerPath: 'settings',

  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/settings',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Settings'],

  endpoints: builder => ({
    getProfile: builder.query({
      query: () => ({
        url: `/profile`,
        method: 'GET',
      }),
      providesTags: ['Settings'],
    }),




    editProfile: builder.mutation({
      query: ({ ...data }) => ({
        url: `/profile`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Settings'],
    }),


  }),
});

export const {
  useGetProfileQuery,

  useEditProfileMutation,

  useBagMutation,
} = settingsApi;
