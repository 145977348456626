import { formatTimeDate } from 'utils/function';
import { QuestBox, QuestTitleBox, QuestImage, QuestTitle, Quest, QuestDate, QuestText, QuestTitleWrap, QuestTextT } from './CardQuest.styled';




const CardQuest= ({ quest }) => {


  return (


    <QuestBox key={quest.id}>
      <QuestTitleBox>
        <QuestTitleWrap>
        <QuestImage src={quest?.user?.merchant?.logo} alt={quest?.merchant?.name} />
        <QuestTitle>{quest?.user?.merchant?.name}</QuestTitle>
        
      <QuestText>{quest?.user?.email?quest?.user?.email:quest?.user?.unconfirmed_email}</QuestText>
        </QuestTitleWrap>
        <QuestDate>{formatTimeDate(quest.createdAt)}</QuestDate>
      </QuestTitleBox>
  
      {/* <QuestText>{quest?.user?.phone}</QuestText> */}

      <QuestTextT>{quest.text}</QuestTextT>
      <Quest>{quest.message}</Quest>
    
    </QuestBox>



  );
};

export default CardQuest;
