
import { LinkNav, ListNav, ListNavItem } from './MainNavToolbar.styled';
import { useLocation, } from 'react-router-dom';
import dayjs from 'dayjs';

import 'dayjs/locale/uk'; // Завантажуємо українську локалізацію

dayjs.locale('uk'); // Встановлюємо українську локалізацію






const MainNavToolbar = () => {



  const location = useLocation();

  const arrays = location.pathname.split('/');
  const currentPage = arrays[arrays.length - 1].includes("-") ? arrays[arrays.length - 2] : arrays[arrays.length - 1];


  return (

    <ListNav>
      <ListNavItem>
        <LinkNav

          to="/"
          className={`${currentPage === 'search_bag'|| currentPage === 'search_order' ? '' : 'active'}`}
        >Користувачі</LinkNav>
      </ListNavItem>
      <ListNavItem>
        <LinkNav

          to={`/main/search_bag`}
          className={`${currentPage === 'search_bag' ? 'active' : ''}`}
        >Кошики</LinkNav>
      </ListNavItem>
      <ListNavItem>
        <LinkNav

          to={`/main/search_order`}
          className={`${currentPage === 'search_order' ? 'active' : ''}`}
        >Замовлення </LinkNav>
      </ListNavItem>

    </ListNav >


  );
};

export default MainNavToolbar;
