import styled from '@emotion/styled';

import { LuLogOut } from 'react-icons/lu';
import { ReactComponent as ExitIcon } from '../../../images/Exit.svg'
export const Button = styled.button`

  display: flex;
 overflow:hidden;
text-wrap:nowrap;
  align-items: center;
background: none;
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-error-600);
outline:none;
&:focus{
  outline: none;
  border: 1px solid var(--colors-error-600);
}
  cursor: pointer;


  border-radius: 16px;
  padding: 8px 12px;
width: 100%;
gap:12px;


`;

export const Icon = styled(ExitIcon)`
  width: 32px;
  height: 32px;
  vertical-align: middle;
  color: currentColor;
  margin-right: 0px;
  &.hovered{
  margin-right: 8px;

}

  /* @media screen and (min-width: 768px) {
    width: 20px;
    height: 20px;
    margin-left: 11px;
  } */
`;
