import React, { useState } from 'react';
import Modal from '../Modal';

import { ModalTitle } from './LogoutModal.styled';


import DeleteAccount from './ExitAccount/ExitAccount';


const LogoutModal = ({ onClose }) => {





  return (
    <Modal onClose={onClose}>

      <DeleteAccount onClose={onClose} />

    </Modal>
  );
};

export default LogoutModal;
