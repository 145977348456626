import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const billingsApi = createApi({
  reducerPath: 'billings',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/billing-plans',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: false,
  keepUnusedDataFor: 1,

  endpoints: (builder) => ({

    // GET: List billing plans
    getBillingPlans: builder.query({
      query: () => '',
      providesTags: ['Billing'],
    }),

    // POST: Creating a new billing plan
    createBillingPlan: builder.mutation({
      query: (newPlan) => ({
        url: '/create',
        method: 'POST',
        body: newPlan,
      }),
      invalidatesTags: ['Billing'],
    }),

    // POST: Updating an existing billing plan
    updateBillingPlan: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `/update?id=${id}`,
        method: 'POST',
        body: updateData,
      }),
      invalidatesTags: ['Billing'],
    }),

    // POST: Deleting a billing plan
    deleteBillingPlan: builder.mutation({
      query: (id) => ({
        url: `/delete?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Billing'],
    }),
  }),
});

export const {
  useGetBillingPlansQuery,
  useCreateBillingPlanMutation,
  useUpdateBillingPlanMutation,
  useDeleteBillingPlanMutation,
} = billingsApi;
