import styled from '@emotion/styled';
import { FiLogIn } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { MdErrorOutline } from 'react-icons/md';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { ReactComponent as EyeIcon } from '../../../images/eye1.svg'
import { ReactComponent as EyeOffIcon } from '../../../images/off.svg'
export const IconEye = styled(EyeIcon)`

  width: 24px;
  height: 24px;
 





 
`;

export const IconEyeOff = styled(EyeOffIcon)`

  width: 24px;
  height: 24px;
 





 
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
flex-direction: column;
gap:16px;
  min-height: 100vh;
  min-width: 100%;
  background: var(--colors-primary-600);
`;

export const StyledForm = styled.form`
  display: flex;
  background: var(--colors-grey-25);
  border-radius:16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  box-shadow: 0 0 40px 0 rgba(195, 195, 195, 0.25);
  padding: 32px;
width:410px;
 
`;

export const Logo = styled.img`


width: 201.67px;
height: 78px;

`;
export const LogoWrap = styled.div`


width: 201.67px;
height: 78px;

`;


export const FormName = styled.h1`
  color: var(--colors-grey-900);
 
font-family: var(--font-family);
font-weight: 600;
font-size: 24px;
line-height: 125%;
text-align: center;
  margin-bottom: 32px;
width: 100%;
  /* @media screen and (min-width: 768px) {
    font-size: 24px;
  } */
`;

export const InputGroupe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
 width:100%;




  /* @media screen and (max-width: 375px) {
    max-width: auto;
  }

  @media screen and (min-width: 768px) {
    width: auto;

  } */
`;

export const InputList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  
`;

export const InputWrapper = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;

 
`;





export const InputWrapperWithIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  Input::placeholder {
    color: var(--colors-grey-400);
        font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;
  
  }
`;

export const Button = styled.button`

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  gap: 10px;

  border-radius: 360px;

background: var(--colors-primary-600);
background:${props => props.disabled ? ' var(--colors-grey-200);' : 'var(--colors-primary-600)'};
  border: none;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  :hover {

    box-shadow: 4px 2px 16px 0px rgba(136, 165, 191, 0.48);
  }

  /* @media screen and (min-width: 375px) {
    width: 287px;
    padding: 14px 103px;
  }

  @media screen and (min-width: 768px) {
    width: 400px;
    padding: 16px 152px;
  } */
`;

export const ButtonText = styled.span`
color: var(--colors-grey-25);
font-family: Manrope;
font-size: 16px;
font-weight: 500;
line-height: 140%;
letter-spacing: 0px;
text-align: center;
`;



export const ShowHideButton = styled.button`
  position: absolute;
  right: 12px;
  top: 50%;
  width: 24px;
height: 24px;
  transform: translateY(-50%);
  color: var(--colors-grey-400);
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  align-items: center;
`;

export const LinksContainer = styled.nav`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  gap: 18px;
`;

export const Label = styled.label`
  margin-bottom: 8px;
  margin-left: 16px;
font-family: var(--font-family);
font-weight: 600;
font-size: 14px;
line-height: 150%;
text-align: left;
  color: var(--colors-grey-800);

  /* @media screen and (min-width: 768px) {
    font-size: 14px;
  } */
  /* &.valid-input {
    color: #3cbc81;
  } */

  /* &.invalid-input {
    color: #ff0000;
  } */

  /* &:hover,
  &:focus,
  &:active {
    color: var(--colors-primary-600);
  } */
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: 48px;
  padding: 8px 16px 8px 16px;
  
font-family: Manrope;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0%;
text-align: left;
border: 1px solid var(--colors-grey-100);
background-color: var(--colors-grey-25)!important;
  ::-webkit-input-placeholder {
 font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-400);
  }
  :-moz-placeholder {
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-400);
  }
  ::-moz-placeholder {
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-400);
  }
  :-ms-input-placeholder {
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-400);
  }

font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
  border-radius: 360px;
background: var(--colors-grey-25);
  border: 1px solid var(--colors-grey-100);
  outline: none;

  /* &.valid-input {
    border: 1px solid #3cbc81;
  } */

  &.invalid-input {
    border: 1px solid #ff0000;
  }

  &:hover,
  &:focus,
  &:active {
    border: 1px solid var(--colors-primary-600);

  }

  /* @media screen and (min-width: 375px) {
    width: 287px;
  }

  @media screen and (min-width: 768px) {
    width: 400px;
    height: 54px;
    padding: 18px;
  } */
   &:-webkit-autofill {
    background-color: var(--colors-grey-25) !important; /* Оновлюємо фон */
    -webkit-box-shadow: 0 0 0px 1000px var(--colors-grey-25) inset; /* Зміна кольору тіні */
    color: var(--colors-grey-900) !important; /* Колір тексту при автозаповненні */
    border: 1px solid var(--colors-grey-100);
    border-radius: 360px;
    transition: background-color 5000s ease-in-out 0s; /* Трюк для запобігання автоматичній зміні кольору */
  }

  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 1px solid var(--colors-primary-600);
    -webkit-box-shadow: 0 0 0px 1000px var(--colors-grey-25) inset; /* Під час фокусу або ховеру */
  }

  &:-webkit-autofill:active {
    background-color: var(--colors-primary-200) !important;
  }
`;

export const Error = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 8px;
  margin-left: auto;
display: flex;
gap:8px;
padding-right: 16px;
align-items: center;
justify-content: end;
  /* &.valid {
    color: #3cbc81;
  } */

  &.invalid {
    color: #ff0000;
  }
`;

export const LinkTo = styled(Link)`
margin-top: 12px;
width: 100%;
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: right;


  color: var(--colors-primary-600);
  font-size: 16px;
  line-height: 14px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

// export const SuccessIcon = styled(IoCheckmarkDoneCircleOutline)`
//   position: absolute;
//   right: 12px;
//   bottom: 22px;
//   width: 18px;
//   height: 18px;
//   color: #3cbc81;

//   @media screen and (min-width: 768px) {
//     bottom: 27px;
//   }
// `;

export const ErrorIcon = styled(MdErrorOutline)`
  /* position: absolute; */
  right: 12px;
  bottom: 37px;
  width: 18px;
  height: 18px;
  color: #ff0000;

  @media screen and (min-width: 768px) {
    bottom: 41px;
  }
`;

export const ContainerErrorIcon = styled.div`
  position: relative;
`;

export const PictureWrapper = styled.div`
  display: ${props => (props.hide ? 'block' : 'none')};
  position: absolute;
  bottom: 19px;
  right: 60px;

  width: 368px;
  height: 521px;

  @media screen and (min-width: 1440px) {
    display: ${props => (props.hide ? 'none' : 'block')};
  }
`;
