import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorText, Formats, ImagePreview, Label, Overlay, DeleteIcon, UploadBox, UploadContainer, IconTrash } from './UploadImageService.styled';
import { updateUserLogo, deleteUserLogo, updateUserBagPicture, deleteUserBagPicture } from '../../../redux/auth/operations';
import { useGetArchiveBagsQuery, useGetBagsQuery } from '../../../redux/bags/bagsApi';
import ImageAnimation2 from 'components/layout/loader/ImageAnimation2';
import { showErrorToast } from 'utils/showToast';

const UploadImageService = ({ img = null,setImage,setSelectedFile, texts, width, height, isLogo = false }) => {

   
   

    const [dragActive, setDragActive] = useState(img ? true : false);
 
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // Допустимі формати файлів
    const allowedFormats = ['image/svg+xml', 'image/png'];
    // Update the image state when the img prop changes
   

    // Функція для обробки перетягування
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // Функція для обробки перетягування файлів
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0]);
        }
    };

    // Обробка вибору файлу через інпут
    const handleChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files[0]);
        }
    };



    // Функція для завантаження файлу
    const handleFile = async (file) => {
        console.log('file.type :>> ', file.type);
        // Перевірка формату файлу
        if (!allowedFormats.includes(file.type)) {
            showErrorToast('Недопустимий формат файлу. Будь ласка, завантажте svg або png');
       
            return;
        }



        const formData = new FormData();
        formData.append('image', file);
        const previewUrl = URL.createObjectURL(file);

        // Оновлюємо стан для зображення та файлу
        setImage(previewUrl);
        setSelectedFile(file); 
      
    };

    // Функція для видалення зображення
    const handleDeleteImage = async () => {
       
        setImage(null);
        setSelectedFile(null);
        setDragActive(false);
    
     
    };

    return (
        <UploadContainer dragActive={img} width={width} height={height}>
            <UploadBox
                width={width}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                dragActive={img}
            >  {loading ? (<ImageAnimation2 />) :
                img ? (
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <ImagePreview width={width} height={height} src={img} alt="Preview" />
                        <Overlay>
                            <DeleteIcon onClick={handleDeleteImage}><IconTrash /> Видалити фото</DeleteIcon>
                        </Overlay>
                    </div>
                ) : (
                    <>
                        <p>{texts}
                            <Label htmlFor="file-upload" className="upload-label"> завантажте зі свого носія</Label>
                        </p>
                        <Formats>(svg, png)</Formats>
                        <input
                            type="file"
                            id="file-upload"
                            accept="image/*"
                            onChange={handleChange}
                            hidden
                        />
              
                    </>
                )}
            </UploadBox>
        </UploadContainer>
    );
};

export default UploadImageService;