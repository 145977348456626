import styled from '@emotion/styled';
export const ButtonWrap = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
gap:12px;
width:100%;


`;
export const ModalTitle = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
text-align: center;
color: var(--colors-grey-900);
width:100%;
`;



export const CancelButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
width:calc((100% - 12px) / 2);

display: flex;
align-items: center;
color: var(--colors-primary-600);
background:none;
border: 1px solid var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;
justify-content: center;
`;

export const SaveButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);
display: flex;
justify-content: center;
width:calc((100% - 12px) / 2);
align-items: center;
background: var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;

`;