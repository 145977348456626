import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../../images/arrow/down.svg';
import { ReactComponent as EditIcon } from '../../../images/edit.svg';
import { ReactComponent as TrashIcon } from '../../../images/trash.svg';


export const IconEdit = styled(EditIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  top:10px;
  right: -34px;
fill:var(--colors-grey-400);
cursor: pointer;
transition: all 0.5s ease;
  &:hover{
    fill:var(--colors-primary-600);
  }

`;

export const IconTrash = styled(TrashIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  top:10px;
right: -68px;
`;


export const QuestList = styled.ul`
display: flex;
flex-wrap: wrap;
justify-content:start;
gap:8px;
width: 100%;
`;

export const QuestItem = styled.li`
position:relative;
width: calc(100% - 68px);
border-radius: 16px;
padding: 12px;
background: var(--colors-grey-25);
`;




export const WrapQuest = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;
height: fit-content;
width: 100%;
`;

export const IconArrowDown = styled(ArrowDown)`
  width: 14px;
  height: 8px;
 transform: ${props => props.up === "true" ? 'rotate(180deg)' : 'none'};
  transition: transform 0.3s ease-in-out; 

`;
export const QuestTitle = styled.h3`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);

`;

export const QuestDesc = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-500);
  max-height: ${props => (props.expanded ? '500px' : '0px')}; 
  overflow: hidden; 
  transition: max-height 0.3s ease-in-out;
`;

export const NavDesc1 = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
text-align: center;
color: var(--colors-grey-600);
margin-bottom: 8px;
`;

export const NavMod = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-primary-600);
`;