import UserNav from '../UserNav';

import {
  Container,
  Wrapper,


} from './SideBar.styled';

import { useEffect, useState } from 'react';


const SideBar = ({ onboard }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Функції для обробки наведення миші
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {

    setIsHovered(false);
  };
  return (

    <Container className={`parent-element ${isHovered ? 'hovered' : ''} ${onboard && 'hovered'} `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave} >
      <Wrapper>

        <UserNav isHovered={isHovered} onboard={onboard} />

      </Wrapper>
    </Container>
  );
};

export default SideBar;
