import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import Image from '../../../images/deletion.png'

import { Box, ButtonWrap, CancelButton,DetailModalBox, DetailModalInput, DetailModalItem, DetailModalItem1, DetailModalItem2, DetailModalLabel, DetailModalText, Img, Label1, LinksContainer, LinkTo, ModalAmountBox, ModalAmountText, ModalAmountValue, ModalInfoText, ModalTitle, NoButton, SaveButton, Text, Title, WrapButton, YesButton } from './AddFaq.styled.js';
import { useCreateFaqCategoryMutation, useDeleteFaqCategoryMutation, useUpdateFaqCategoryMutation } from '../../../redux/faq/faqApi';


import UploadImageService from 'components/services/UploadImageService';



const AddFaq = ({ onClose, title, types, theme }) => {
  const [createFaqCategory] = useCreateFaqCategoryMutation();
  const [updateFaqCategory] = useUpdateFaqCategoryMutation();
  const [deleteFaqCategory] = useDeleteFaqCategoryMutation();

  const [themeName, setThemeName] = useState(theme?.title || '');
  const [themeDesc, setThemeDesc] = useState(theme?.description || '');
  const [themeImg, setThemeImg] = useState(theme?.image || '');
  const [selectedFile, setSelectedFile] = useState(null);

  // Зберігаємо початкові значення
  const [initialThemeName] = useState(theme?.title || '');
  const [initialThemeDesc] = useState(theme?.description || '');
  const [initialThemeImg] = useState(theme?.image || '');

  // Стан для кнопки "Зберегти"
  const [isDisabled, setIsDisabled] = useState(true);

  // Перевірка на зміни
  useEffect(() => {
    // Перевіряємо, чи змінилося хоча б одне з полів для 'edit'
    const isChanged =
      themeName !== initialThemeName ||
      themeDesc !== initialThemeDesc ||
      themeImg !== initialThemeImg ||
      selectedFile;

    // Для 'add' поля не повинні бути порожніми
    const isAddDisabled = !themeName || !themeDesc || !themeImg;

    setIsDisabled(types === 'add' ? isAddDisabled : !isChanged);
  }, [themeName, themeDesc, themeImg, selectedFile, initialThemeName, initialThemeDesc, initialThemeImg, types]);

  const handleSave = async () => {
    if (isDisabled) return;

    const formData = new FormData();
    const data = {};

    // Якщо це тип 'add', перевіряємо, чи заповнені всі поля
    if (types === 'add') {
      formData.append('title', themeName);
      formData.append('description', themeDesc);
    
      if (selectedFile) formData.append('image', selectedFile);
    } else {
      // Якщо це 'edit', передаємо тільки змінені поля і id
      if (themeName !== initialThemeName) formData.append('title', themeName);
      if (themeDesc !== initialThemeDesc) formData.append('description', themeDesc);
      console.log('object :>> ', selectedFile);
      // if (themeImg !== initialThemeImg) {formData.append('image', selectedFile || themeImg); }
      if (selectedFile) formData.append('image', selectedFile);  // Якщо вибрано нове зображення
    
 
    }

    // Логування formData
   

    try {
      if (types === 'add') {

        await createFaqCategory(formData).unwrap();
      } else {
        console.log('Sending formData:', formData);  
      console.log('data :>> ', data);
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }
      console.log('object :>> ', {id:theme.id, data:formData});
        await updateFaqCategory({id:theme.id, data:formData}).unwrap();
      }
    } catch (error) {
      console.error('Failed to save:', error);
    }
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      {types !== 'del' ? (
        <>
          <ModalTitle>{title}</ModalTitle>
          <DetailModalBox>
            <DetailModalItem1>
              <DetailModalItem2>
                <DetailModalLabel htmlFor='themeName'>Назва теми/проблематики</DetailModalLabel>
                <DetailModalInput
                  id='themeName'
                  name='themeName'
                  placeholder='Введіть тему/проблематику'
                  value={themeName}
                  onChange={(e) => { setThemeName(e.target.value); }}
                />
              </DetailModalItem2>
              <UploadImageService
                img={themeImg}
                setImage={setThemeImg}
                setSelectedFile={setSelectedFile}
                texts={'Перетягніть сюди фото або'}
                width={'160px'}
                height={'160px'}
                isLogo={true}
              />
            </DetailModalItem1>
            <DetailModalItem>
              <DetailModalLabel htmlFor='themeDesc'>Опис теми/проблематики </DetailModalLabel>
              <DetailModalText
                id='themeDesc'
                name='themeDesc'
                placeholder='Введіть опис'
                value={themeDesc}
                onChange={(e) => { setThemeDesc(e.target.value); }}
              />
            </DetailModalItem>
          </DetailModalBox>

          <ButtonWrap>
            <CancelButton onClick={onClose}>Скасувати</CancelButton>
            <SaveButton onClick={handleSave} disabled={isDisabled}>Зберегти</SaveButton>
          </ButtonWrap>
        </>
      ) : (
        <Box>
          <Img src={Image} alt='Вихід' />
          <Title>{title}</Title>
          <Text>Ви впевнені, що хочете видалити тему? Видаляться також й усі питання та відповіді в ній.</Text>
          <WrapButton>
            <NoButton onClick={onClose}>Ні</NoButton>
            <YesButton
              onClick={() => { deleteFaqCategory(theme.id); onClose(); }}
            >Так, видалити</YesButton>
          </WrapButton>
        </Box>
      )}
    </Modal>
  );
};

export default AddFaq;
