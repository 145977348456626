
import { LinkNav, ListNav, ListNavItem } from './ServiceNavToolbar.styled';
import { useLocation, } from 'react-router-dom';
import dayjs from 'dayjs';

import 'dayjs/locale/uk'; // Завантажуємо українську локалізацію

dayjs.locale('uk'); // Встановлюємо українську локалізацію






const ServiceNavToolbar= () => {



  const location = useLocation();

  const arrays = location.pathname.split('/');
  const currentPage = arrays[arrays.length - 1].includes("-") ? arrays[arrays.length - 2] : arrays[arrays.length - 1];


  return (

    <ListNav>
      <ListNavItem>
        <LinkNav

          to="/services"
          className={`${currentPage === 'question' ? '' : 'active'}`}
        >Типові питання</LinkNav>
      </ListNavItem>
      <ListNavItem>
        <LinkNav

          to={`/services/question`}
          className={`${currentPage === 'question' ? 'active' : ''}`}
        >Питання від користувачів</LinkNav>
      </ListNavItem>

    </ListNav >


  );
};

export default ServiceNavToolbar;
