


import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';


const loadingAnimation = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
`;
const Containerlogo = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-size: cover;
  background-position: center;
display: flex;
align-items: center;
justify-content: center;
background-color: rgb(255,255,255,0.2);
`;

const Loader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderBar = styled.div`
  width: 8px;
  height: 80px;
  margin: 0 5px;
  background-color: var(--colors-primary-600);
  animation: ${loadingAnimation} 0.8s infinite ease-in-out;
  &:nth-of-type(1) {
    animation-delay: 0s;
  }
  &:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  &:nth-of-type(4) {
    animation-delay: 0.3s;
  }
  &:nth-of-type(5) {
    animation-delay: 0.4s;
  }
`;
export { LoaderBar, Loader, loadingAnimation, LoaderBarContainer, Containerlogo };