import FinanceDocument from 'components/docs/FinanceDocument';




const DocsListPage = () => {
  return <FinanceDocument />;
};

export default DocsListPage;
