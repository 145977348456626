import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const faqApi = createApi({
  reducerPath: 'faq',

  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      } 

      return headers;
    },
  }),

  tagTypes: ['Faq', 'MerchantFeedbacks'],

  endpoints: builder => ({
    // Отримання списку FAQ
    getFaq: builder.query({
      query: () => ({
        url: `/faq`,
        method: 'GET',
      }),
      providesTags: ['Faq'],
    }),

    // Створення нового елемента FAQ
    createFaq: builder.mutation({
      query: data => ({
        url: '/merchant-feedbacks/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Faq'],
    }),

    // Створення нової категорії FAQ
    createFaqCategory: builder.mutation({
      query: data => ({
        url: '/faq/create-category',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Faq'],
    }),

    // Оновлення категорії FAQ
    updateFaqCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/faq/update-category?id=${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Faq'],
    }),

    // Видалення категорії FAQ
    deleteFaqCategory: builder.mutation({
      query: id => ({
        url: `/faq/delete-category?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Faq'],
    }),

    // Створення нового елемента FAQ
    createFaqItem: builder.mutation({
      query: data => ({
        url: '/faq/create-item',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Faq'],
    }),

    // Оновлення елемента FAQ
    updateFaqItem: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/faq/update-item?id=${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Faq'],
    }),

    // Видалення елемента FAQ
    deleteFaqItem: builder.mutation({
      query: id => ({
        url: `/faq/delete-item?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Faq'],
    }),

    // Отримання списку MerchantFeedbacks
    getMerchantFeedbacks: builder.query({
      query: ({ page = 1} = {}) => {
        const params = { page };
        return {
        url: '/merchant-feedbacks',
        method: 'GET',
        params,
      }},
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['MerchantFeedbacks'],
    }),
  }),
});

export const {
  useGetFaqQuery,
  useCreateFaqMutation,
  useCreateFaqCategoryMutation,
  useUpdateFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
  useCreateFaqItemMutation,
  useUpdateFaqItemMutation,
  useDeleteFaqItemMutation,
  useGetMerchantFeedbacksQuery,
} = faqApi;