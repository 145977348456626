import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const categoriesApi = createApi({
  reducerPath: 'categories',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: false,
  keepUnusedDataFor: 1,

  endpoints: (builder) => ({
    
    // GET: Fetch categories
    getCategories: builder.query({
      query: () => '/dictionaries/categories',
      providesTags: ['Category'],
    }),

    // POST: Creating a new category
    createCategory: builder.mutation({
      query: (newCategory) => ({
        url: '/categories/create',
        method: 'POST',
        body: newCategory,
      }),
      invalidatesTags: ['Category'],
    }),

    // POST: Updating an existing category
    updateCategory: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `/categories/update?id=${id}`,
        method: 'POST',
        body: updateData,
      }),
      invalidatesTags: ['Category'],
    }),

    // POST: Deleting a category
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/categories/delete?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Category'],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;