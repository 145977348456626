import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const documentsApi = createApi({
  reducerPath: 'documents',

  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/documents',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Documents'],

  endpoints: builder => ({
 
    getDocuments: builder.query({
      query: () => ({
        url: `/list`,
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    viewDocument: builder.query({
      query: (id) => ({
        url: `/view?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    downloadDocument: builder.query({
      query: (id) => ({
        url: `/download?id=${id}`,
        method: 'POST',
        responseHandler: (response) => response.blob(), // Обробка бінарних даних
      }),
      transformResponse: (response) => response,
      providesTags: ['Documents'],
    }),
    createDocument: builder.mutation({
      query: (data) => ({
        url: `/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Documents'],
    }),
    updateDocument: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update?id=${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteDocument: builder.mutation({
      query: (id) => ({
        url: `/delete?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Documents'],
    }),

  }),
});

export const {
  useGetDocumentsQuery,
  useViewDocumentQuery,
  useDownloadDocumentQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
} = documentsApi;
