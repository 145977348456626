import { createPortal } from 'react-dom';
import {

  ModalContent,
  ModalOverlay,
  CloseBtn,
} from './Modal.styled';

const modalRoot = document.querySelector('#root-module');

const Modal = ({ children, onClose, pad, heightT = false }) => {
  const handleContentClick = event => {
    event.stopPropagation();
  };

  return createPortal(
    <ModalOverlay onClick={onClose}>
      <ModalContent pad={pad} onClick={handleContentClick} heightT={heightT ? 'true' : 'false'} >

        {pad !== "false" && <CloseBtn onClick={onClose} />}

        {children}
      </ModalContent>
    </ModalOverlay>,
    modalRoot
  );
};

export default Modal;
