import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateDocumentMutation, useDeleteDocumentMutation, useUpdateDocumentMutation, useViewDocumentQuery } from '../../../redux/documents/documentsApi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CustomQuill.css'; // Додаткові кастомні стилі для Quill

import {
  BagsBox,
  BagsTitleBox,
  BagsBodyBox,
  BagsTitle,
  SaleButton,
  ButtonWrap,
  Label,
  Input
} from './AddDoc.styled';
import { useFetchDocumentTypesQuery } from '../../../redux/dictionary/dictionariesApi';
import CustomSelect from 'components/other/CustomSelect';
import { Label1 } from 'components/modal/ServicesModal/ServicesModal.styled';
import ImageAnimation1 from 'components/layout/loader/ImageAnimation1';


const AddDoc = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type= searchParams.get('type');
  const { data: doc, isLoading: isLoadingDoc } = useViewDocumentQuery(id, {
    skip: id === 'id',
  });
  const { data: docType, isLoading:docsTypeLoading } = useFetchDocumentTypesQuery();
  const [selectedValue, setSelectedValue] = useState("Оберіть тип документа");
  const [isSelectOpen, setIsSelectOpen] = useState("false");
  const [createDocument]=useCreateDocumentMutation();
  const [editDocument]=useUpdateDocumentMutation();
  const [deleteDocument]=useDeleteDocumentMutation();
  const [types, setTypes] = useState([]);
  const [keys, setKeys] = useState([]);
  
  console.log('doc:>> ', doc);

  const [docName, setDocName] = useState('');

  const [htmlContent, setHtmlContent] = useState('');
  const handleSelectChange = (event) => {
    setSelectedValue(event);
    handleToggleOpen()
  };

  const handleToggleOpen = () => {
    isSelectOpen === "true" ? setIsSelectOpen("false") : setIsSelectOpen("true");
  };
  const handleChange = (content) => {
    setHtmlContent(content);
  };

  const handleNameChange = (content) => {
    setDocName(content);
  };
  const handleSubmit = () => {
const selectTypesIndex=types.findIndex(item=>selectedValue)

    try {
      if (id === 'id') {
        createDocument({ type: keys[selectTypesIndex], text: htmlContent,appointment:type });
      } else {
        editDocument({ id,data:{text: htmlContent } });
      }
    } catch (error) {
      console.error("Error submitting document:", error);
    }
  };

  const formatType=(str)=>{
    switch (str) {
      case 'client':
      return 'додатку';
       case 'merchant':
      return 'кабінету мерчанту';
    
      default:
        return 'всіх';
    }


};


useEffect(()=>{
  if (doc) {
setSelectedValue(doc?.data?.typeName) ;
setHtmlContent(doc?.data?.text) ; 

  }
},[doc])


useEffect(() => {
  if (docType) {
    console.log('docType :>> ', Object.values(docType.data));
    setTypes(Object.values(docType.data))
    setKeys(Object.keys(docType.data))

  }
}, [docType]);

  // Налаштування для панелі інструментів
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }], // Заголовки
      [{ 'font': [] }],                 // Шрифти
      [{ 'size': ['small', false, 'large', 'huge'] }], // Розміри тексту
      [{ 'color': [] }, { 'background': [] }],         // Кольори
      ['bold', 'italic', 'underline', 'strike'],       // Стилі тексту
      [{ 'align': [] }],                               // Вирівнювання
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],   // Списки
      [{ 'indent': '-1' }, { 'indent': '+1' }],        // Відступи
      [{ 'script': 'sub' }, { 'script': 'super' }],    // Нижній і верхній індекси
      ['blockquote', 'code-block'],                    // Цитата і код-блок
      [{ 'direction': 'rtl' }],                        // Напрямок тексту
      ['link'],                                        // Додавання посилання
      ['clean']                                        // Очищення форматування
    ],
  };

  const formats = [
    'header', 'font', 'size', 'color', 'background',
    'bold', 'italic', 'underline', 'strike', 'align',
    'list', 'bullet', 'indent', 'script',
    'blockquote', 'code-block', 'direction', 'link',
  ];



  return (
    <BagsBox>
      <BagsTitleBox>
        <BagsTitle>{id === 'id' ? `Додати документ для ${formatType(type)}`: `Редагувати документ для ${formatType(type)}`}</BagsTitle>
        <ButtonWrap>
          <SaleButton type='button' onClick={handleSubmit}>Зберегти</SaleButton>
        </ButtonWrap>
      </BagsTitleBox>
{isLoadingDoc&&docsTypeLoading?<ImageAnimation1/>:
      <BagsBodyBox>

        <CustomSelect
          width={"100%"}
          options={["Оберіть тип документа", ...types.map(section => section)]}
          selectedValue={selectedValue}
          onChange={handleSelectChange}
          disabled={id==='id'?false:true}
        />
        
        <ReactQuill
          value={htmlContent}
          onChange={handleChange}
          modules={modules} // Панель інструментів
          formats={formats} // Формати для налаштування
          placeholder="Введіть HTML-контент"
        />
      </BagsBodyBox>}
    </BagsBox>
  );
};

export default AddDoc;