import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as SentIcon } from '../../../images/sent.svg';
import { ReactComponent as SbagIcon } from '../../../images/shoppingBag.svg';
export const ButtonWrap = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
gap:12px;
width:100%;


`;
export const ModalHeadBox = styled.div`
border-bottom: 1px solid var(--colors-grey-200);
padding: 16px;
width: 100%;
height: 59px;
display: flex;
align-items: center;
justify-content: space-between;
`;
export const ModalTitle = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
color: var(--colors-grey-800);

`;
export const ModalHeadBtn = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-primary-600);
display: flex;
align-items: center;
justify-content: space-between;
padding: 0px 0px 2px 0px;
width: 142px;
height: 26px;
background: none;
border:none;
cursor: pointer;
`;

export const IconSend = styled(SentIcon)`

width: 24px;
height: 25px;
`;



export const IconSbag = styled(SbagIcon)`

width: 24px;
height: 24px;
`;




export const NotifyModalList = styled.ul`
background: none;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
overflow-y:auto;

`;
export const NotifyModalItem = styled.li`
background-color: ${props => props.read === "false" ? "var(--colors-primary-50)" : "var(--colors-grey-25)"};
border-bottom: 1px solid var(--colors-grey-200);
padding: 16px;
width: 480px;
height: fit-content;
cursor: pointer;
display: flex;
gap:16px;
`;

export const NotifyModalInfoBox = styled.div`
display: flex;
flex-direction: column;
gap:5px ;

height: fit-content;
align-items: start;
width: calc(100% - 40px);
`;

export const NotifyModalTitle = styled.h3`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
`;

export const NotifyModalText = styled.h3`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-500);
`;

export const NotifyModalTime = styled.h3`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-400);
`;