import styled from '@emotion/styled';
import { ReactComponent as TrashIcon } from '../../../images/trash1.svg';
export const UploadContainer = styled.div`
  display: flex;
justify-content:${(props) => (props.dragActive ? 'flex-start' : 'center')};
  align-items: center;
  height: ${(props) => props.height};
  width: 160px;
`;

export const UploadBox = styled.div`
  border: ${(props) => (props.dragActive ? 'none' : '1px dashed var(--colors-primary-600)')};
  border-radius: 16px;
  height: 100%;
  width:  ${(props) => (props.dragActive ? props.width : '100%')};;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: border-color 0.3s ease;
`;

export const ImagePreview  = styled.div`
background-image: url(${(props) => props.src});
background-size: contain;
background-repeat: no-repeat;
background-position: center;
border-radius: 16px;
height: ${(props) => props.height};
width: ${(props) => props.width};
`;


export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 16px;
  
  &:hover {
    opacity: 1;
  }
`;

export const DeleteIcon = styled.button`
  background-color: transparent;
  border: none;

  cursor: pointer;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);
gap: 8px;
  flex-direction: column;
  display: flex;
  align-items: center;
`;



export const UploadText = styled.p`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  text-align: center;
  color: var(--colors-grey-900);
`;

export const Formats = styled.p`
  margin-top: 4px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: var(--colors-grey-400);
`;

export const Label = styled.label`
  font-family: var(--font-family);
  cursor: pointer;
  font-size: 16px;
  line-height: 137%;
  text-align: center;
  font-weight: 600;
  color: var(--colors-primary-600);
`;

export const ErrorText = styled.p`
  font-family: var(--font-family);
  color: red;
  font-size: 14px;
  margin-top: 8px;
`;

export const IconTrash = styled(TrashIcon)`
  width: 32px;
  height: 32px;
 

`;