import styled from '@emotion/styled';
import { ReactComponent as EyeIcon } from '../../../images/eye.svg';
import { ReactComponent as SearchIcon } from '../../../images/search.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as Back } from '../../../images/arrow/right.svg';
import { ReactComponent as BlockIcon } from '../../../images/stop-sign.svg';
import { ReactComponent as UnBlockIcon } from '../../../images/octagon.svg';
import { ReactComponent as TrashIcon } from '../../../images/trash.svg';
import { ReactComponent as UnTrashIcon } from '../../../images/up.svg';
import { ReactComponent as PlayIcon } from '../../../images/play.svg';

import { color } from 'framer-motion';
import { Opacity } from '@mui/icons-material';
export const BagsBox = styled.div`
border-radius: 16px;
padding: 16px;
width: 100%;
background: var(--colors-grey-50);
  /* @media screen and (min-width: 375px) {
    width: 375px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
    display: flex;
    max-height: 100vh;
  } */
`;



export const BagsTitleBox = styled.div`
  display: flex;
align-items: center;
justify-content: space-between;
border-bottom: 1px solid var(--colors-grey-200);
padding: 0px 0px 12px 0px;
width:100%;

margin-bottom: 16px;
`;

export const BagsBodyBox = styled.div`
width: 100%;
height: fit-content;
padding:0 0 18px 0;
`;
export const FilterBox = styled.div`
display: flex; 
align-items: center;
gap: 16px;
`;
export const BagsTitle = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
color: var(--colors-grey-800);
`;



export const StatusWrap = styled.div`
display: flex; 
align-items: center;
border-radius: 360px;
padding: 4px 12px;
background-color: ${({ color }) => color};
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-25);
width:fit-content ;
margin: 0 auto;
`;

export const IconEye = styled(EyeIcon)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  stroke: var(--colors-primary-600);
  cursor: pointer;
  color: var(--colors-grey-25);
`;

export const IconPlay = styled(PlayIcon)`
outline: none;
  width: 24px;
  height: 24px;
  font-size: 24px;
  stroke:${ props=>props.color==="true"?'var(--colors-secondary-500)':'var(--colors-grey-400)'};
  cursor: pointer;
  color: var(--colors-grey-25);

  &:focus{
    outline: none;
    border:none;
  }


`;

export const IconSearch = styled(SearchIcon)`
  width: 24px;
  height: 24px;
position: absolute;
top:50%;
left:16px;
transform: translateY(-50%);
  stroke: currentColor;
  cursor: pointer;
  &:focus{
    outline: none;
    border:none;
  }
`;

export const IconTrash = styled(TrashIcon)`
appearance: unset;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 1;
  outline: none;
  border:none;
  &:focus{
    outline: none;
    border:none;
  }

`;
export const IconUnTrash = styled(UnTrashIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  stroke:var(--colors-secondary-600);
  opacity: 1;
  &:focus{
    outline: none;
    border:none;
  }
`;

export const IconBlock= styled(BlockIcon)`
  width: 24px;
  height: 24px;
  fill:var(--colors-error-600);
  cursor: pointer;
  opacity: 1;
  &:focus{
    outline: none;
    border:none;
  }
`;

export const IconUnBlock= styled(UnBlockIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
fill:var(--colors-secondary-600);
 position: relative;
 z-index: 2;
opacity: 1;
&:focus{
    outline: none;
    border:none;
  }
`;


export const BagsNoText = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-500);
`;


export const LabelR = styled.label`
position: absolute;
  display: inline-block;
  right: 0;
`;

export const ArchiveLink = styled(Link)`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-primary-600);
display: flex;
gap: 8px;
align-items: center;
justify-content: center;
margin: 0 auto;
margin-top: 16px;
`;



// Styled components for the table and pagination
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: var(--font-family);
  background-color: var(--colors-grey-25);
border-radius: 16px;
`;

export const TableHead = styled.thead`
  background-color: var(--colors-primary-600);
  color: var(--colors-grey-25);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  border-radius: 16px 16px 0 0;
`;

export const TableHeader = styled.th`
  padding: 12px;
  font-weight: 600;
text-align: center;
  

  &:first-of-type {
    border-radius: 16px 0 0 0;
  }

 &:nth-of-type(1) { width: 40px; padding: 14px 12px; text-align:left } /* Назва диво-кошику */
  &:nth-of-type(2) { width: 220px;max-width:220px; text-align: left; } /* Кількість */
  &:nth-of-type(3) {width: 160px;max-width:160px;  text-align: left; } /* Вартість */
  &:nth-of-type(4) {width: 180px; max-width:180px; text-align: left; } /* Вартість*/
  &:nth-of-type(5) {width: 130px; text-align: left; } /* Вартість*/
  &:nth-of-type(6) { width: 150px; text-align: center; } /* Статус */
  &:nth-of-type(7) { width: 230px; min-width:230px;text-align: center; } /* Id */


  &:last-of-type {
    border-radius: 0 16px 0 0;
  }
`;

export const TableRow = styled.tr`
 
`;

export const TableBody = styled.tbody`
 
`;

export const TableData = styled.td`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
${props=>props.del?'opacity:0.6':'opasity:1'};
color: var(--colors-grey-900);
  padding: 12px;

  border-bottom: 1px solid var(--colors-grey-50);
 &:nth-of-type(1) { width: 40px; padding: 14px 12px; text-align:left } 
   &:nth-of-type(2) { width: 220px; min-width:220px; text-align: left;overflow:hidden; text-overflow:ellipsis; white-space:nowrap } 
     &:nth-of-type(3) {width: 160px; max-width:160px; text-align: left;overflow:hidden; text-overflow:ellipsis; white-space:nowrap } /* Вартість */
  &:nth-of-type(4) { width: 180px; max-width:180px;overflow:hidden; text-overflow:ellipsis; white-space:nowrap; text-align: left; & div{
    margin: 0;
  } } /* Статус */
  &:nth-of-type(5) { width: 100px; text-align: left; & div{
    margin: 0;
  } } 
  &:nth-of-type(6) { width: 150px;max-width:150px; text-align: center; & div{
    margin: 0;
  } } 
  &:nth-of-type(7) { width: 100%;min-width:240px; display: flex;justify-content: center; gap:15px; } 
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
 gap:8px;
`;
export const PageNumbersContainer = styled.div`
  display: flex;
  justify-content: center;
gap:1px;
border-radius: 8px;
  border: 1px solid var(--colors-secondary-500);
`;

export const PaginationButton = styled.button`


font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-25);

 border-radius: 6px;
width: 32px;
height: 32px;
  background: ${props => (props.disabled ? 'var(--colors-secondary-500);' : 'none')};
  color: ${props => (props.disabled ? 'var(--colors-grey-25)' : ' var(--colors-grey-800)')};
  font-size: 16px;
  cursor: ${props => (props.disabled ? '' : 'pointer')};

  &:hover {
    background-color: ${props => (props.disabled ? 'var(--colors-secondary-500)' : 'var(--colors-grey-50)')};
  }
`;

export const PrevIcon = styled(Back)`
opacity: ${props => (props.disabled ? '0.4' : '1')};
 cursor:${props => (props.disabled ? 'unset' : 'pointer')}; 
  width: 32px;
  height: 32px;
 
transform: rotate(180deg);
 color: var(--colors-grey-400);



 
`;



export const NextIcon = styled(Back)`

  opacity: ${props => (props.disabled ? '0.4' : '1')};
 cursor:${props => (props.disabled ? 'unset' : 'pointer')}; 
  width: 32px;
  height: 32px;
 

 color: var(--colors-grey-400);



 
`;

export const InfoBox = styled.div`
 padding: 18px 0;
margin-bottom: 16px;
`;


export const InfoText = styled.p`
 font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-500);

`;


export const SearchInput = styled.input`
width: 420px;
height: 48px;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
background: var(--colors-grey-25);
border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
padding-left:48px ;
padding-right:110px ;
color: var(--colors-grey-900);
&::placeholder{
  color: var(--colors-grey-400);
}
outline: none;
`;

export const SearchLabel = styled.label`

position: relative;


`;


export const CustomLabel = styled.label`
cursor: pointer;
border-radius: 40px;
background-color: var(--colors-grey-300);
width:44px; 
height:24px;
position: relative;
&::before{
  content:"";
  border-radius: 360px;
width: 20px;
height: 20px;
background: var(--colors-grey-25);
top:2px;
left:2px;
 transition: transform 0.3s ease;
  position: absolute;
}
`;
export const Checkbox = styled.input`
 opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  &:checked + label {
    background-color: var(--colors-primary-600);

    &::before {
      transform: translateX(20px);
    }
  }
`;