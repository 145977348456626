import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--colors-grey-25);
  position: relative;
  height: 100vh;
overflow: hidden;
/*   
  @media screen and (min-width: 375px) {
    width: 375px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
    display: flex;
    max-height: 100vh;
  } */
`;



export const Wrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-wrap: nowrap;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
height: 100vh;
  width: 100%;
  padding: 32px 80px;
  overflow:auto;
`;

export const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  /* flex: 1; */

height: 98%;
`;
