import { useEffect, useState } from "react";
import { useGetDocumentsQuery } from "../../../redux/documents/documentsApi";
import { AddBagButton, BagsBodyBox, BagsBox, BagsTitle, BagsTitleBox, DownloadBox, DownloadButtonBox, DownloadName, IconDownload, IconEdit, IconEye, IconPlus } from "./FinanceDocument.styled";
import { useNavigate } from "react-router-dom";
import DocsViewModal from "components/modal/DocsViewModal/DocsViewModal";
import convertHtmlToPdf from "utils/function";
import { AllBox } from "components/finance/Finance/Finance.styled";
import { useFetchDocumentTypesQuery } from "../../../redux/dictionary/dictionariesApi";

const FinanceDocument = () => {
  const [viewDocId, setViewDocId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectItem, setSelectItem] = useState({});
  const [downloadDocId, setDownloadDocId] = useState(null);
  const [uDocs, setUDocs] = useState([]);
  const [types, setTypes] = useState([]);
  const [mDocs, setMDocs] = useState([]);
  const [aDocs, setADocs] = useState([]);
  const { data: docData, isLoading:docsLoading } = useGetDocumentsQuery();
  const { data: docType, isLoading:docsTypeLoading } = useFetchDocumentTypesQuery();
const navigate=useNavigate();


  useEffect(() => {
    if (docData) {
      setUDocs(docData.filter(item=>item.appointment==='client'))
      setMDocs(docData.filter(item=>item.appointment==='merchant'))
      setADocs(docData.filter(item=>item.appointment==='all'))

    }
  }, [docData]);
  useEffect(() => {
    if (docType) {
      console.log('docType :>> ', docType);
      setTypes(Object.entries(docType.data))
  

    }
  }, [docType]);

  const handleOpenModal = (item) => {
    setSelectItem(item)
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    document.body.style.overflow = 'auto';

  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);
  const handleDownloadPdf = (item) => {
    convertHtmlToPdf(item);
  };
 

  return (<AllBox>
    <BagsBox>
      <BagsTitleBox>
        <BagsTitle>Документи для додатку</BagsTitle>
        {types.length>uDocs.length&&<AddBagButton  onClick={() => { navigate("adddoc/id?type=client") }} ><IconPlus /> Додати документ</AddBagButton>}
      </BagsTitleBox>

      <BagsBodyBox>
{uDocs.map(item=>{
  return(
    <DownloadBox key={item.id}>
    <DownloadName>
      {item?.typeName}
    </DownloadName>
    <DownloadButtonBox>
    <IconEdit onClick={() => navigate(`adddoc/${item.id}?type=client`)} />
      <IconEye onClick={() => handleOpenModal(item)} />
      <IconDownload onClick={() => handleDownloadPdf(item)} />
    </DownloadButtonBox>
  </DownloadBox>
  )
})}

      </BagsBodyBox>
    
    </BagsBox>
        <BagsBox>
        <BagsTitleBox>
          <BagsTitle>Документи для кабінету мерчанта</BagsTitle>
         {types.length>mDocs.length&&<AddBagButton  onClick={() => { navigate("adddoc/id?type=merchant") }} ><IconPlus /> Додати документ</AddBagButton>}
        </BagsTitleBox>
  
        <BagsBodyBox>
  {mDocs.map(item=>{
    return(
      <DownloadBox key={item.id}>
      <DownloadName>
        {item?.typeName}
      </DownloadName>
      <DownloadButtonBox>
      <IconEdit onClick={() => navigate(`adddoc/${item.id}?type=merchant"`)} />
        <IconEye onClick={() => handleOpenModal(item)} />
        <IconDownload onClick={() => handleDownloadPdf(item)} />
      </DownloadButtonBox>
    </DownloadBox>
    )
  })}
  
        </BagsBodyBox>
      
      </BagsBox>
          <BagsBox>
          <BagsTitleBox>
            <BagsTitle>Загальні документи</BagsTitle>
            {types.length>aDocs.length&&<AddBagButton  onClick={() => { navigate("adddoc/id?type=all") }} ><IconPlus /> Додати документ</AddBagButton>}
          </BagsTitleBox>
    
          <BagsBodyBox>
    {aDocs.map(item=>{
      return(
        <DownloadBox key={item.id}>
        <DownloadName>
          {item?.typeName}
        </DownloadName>
        <DownloadButtonBox>
        <IconEdit onClick={() => navigate(`adddoc/${item.id}?type=all`)} />
          <IconEye onClick={() => handleOpenModal(item)} />
          <IconDownload onClick={() => handleDownloadPdf(item)} />
        </DownloadButtonBox>
      </DownloadBox>
      )
    })}
    
          </BagsBodyBox>
        
        </BagsBox>
      {isModalOpen && (
        <DocsViewModal onClose={handleCloseModal} item={selectItem} handleDownloadPdf={handleDownloadPdf} />
      )}</AllBox>
  );
  
};

export default FinanceDocument;
