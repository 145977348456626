import { useState, useEffect } from 'react';
import image1 from '../../../images/Group.png';
import { Containerlogo, Loader, LoaderBar, LoaderBarContainer } from './ImageAnimation.styled';
import { Logo } from '../../../components/auth/LoginForm/LoginForm.styled';
const ImageAnimation = () => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Імітація часу завантаження
    return () => clearTimeout(timer);
  }, []);

  return (
    <Containerlogo>

      <Loader>
        <LoaderBarContainer>
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
        </LoaderBarContainer>
      </Loader>
    </Containerlogo>
  );
};
export default ImageAnimation;