import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import Image from '../../../images/deletion.png'

import { Box, ButtonWrap, CancelButton,  DetailModalBox, DetailModalInput, DetailModalItem, DetailModalLabel, DetailModalText, Img, Label1, LinksContainer, LinkTo, ModalAmountBox, ModalAmountText, ModalAmountValue, ModalInfoText, ModalTitle, NoButton, SaveButton, Text, Title, WrapButton, YesButton } from './AddFaqItem.styled.js';

import { useCreateFaqCategoryMutation, useCreateFaqItemMutation, useDeleteFaqCategoryMutation, useDeleteFaqItemMutation, useUpdateFaqCategoryMutation, useUpdateFaqItemMutation } from '../../../redux/faq/faqApi';


const AddFaqItem = ({ onClose, title, types, themeItem,categoryId }) => {
  const [createFaqCategory] = useCreateFaqItemMutation();
  const [updateFaqCategory] = useUpdateFaqItemMutation();
  const [deleteFaqCategory] = useDeleteFaqItemMutation();

  const [themeName, setThemeName] = useState(themeItem?.title || '');
  const [themeDesc, setThemeDesc] = useState(themeItem?.description || '');



  // Зберігаємо початкові значення
  const [initialThemeName] = useState(themeItem?.title || '');
  const [initialThemeDesc] = useState(themeItem?.description || '');


  // Стан для кнопки "Зберегти"
  const [isDisabled, setIsDisabled] = useState(true);

  // Перевірка на зміни
  useEffect(() => {
    // Перевіряємо, чи змінилося хоча б одне з полів для 'edit'
    const isChanged =
      themeName !== initialThemeName ||
      themeDesc !== initialThemeDesc ;
  

    // Для 'add' поля не повинні бути порожніми
    const isAddDisabled = !themeName || !themeDesc;

    setIsDisabled(types === 'add' ? isAddDisabled : !isChanged);
  }, [themeName, themeDesc, initialThemeName, initialThemeDesc,  types]);

  const handleSave = async () => {
    if (isDisabled) return;

    const formData = {};

    // Якщо це тип 'add', перевіряємо, чи заповнені всі поля
    if (types === 'add') {
      formData.title=themeName;
      formData.description=themeDesc;
      formData.category_id=categoryId;
    } else {
      // Якщо це 'edit', передаємо тільки змінені поля і id
      if (themeName !== initialThemeName) formData.title=themeName;
      if (themeDesc !== initialThemeDesc) formData.description=themeDesc;
   
    
 
    }

    // Логування formData
   
console.log('themeItem :>> ', themeItem);
console.log('types :>> ', types);
    try {
      if (types === 'add') {

        await createFaqCategory(formData).unwrap();
      } else {

        await updateFaqCategory({category_id:categoryId ,id:themeItem.id, ...formData}).unwrap();
      }
    } catch (error) {
      console.error('Failed to save:', error);
    }
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      {types !== 'del' ? (
        <>
          <ModalTitle>{title}</ModalTitle>
          <DetailModalBox>
            <DetailModalItem>
            
                <DetailModalLabel htmlFor='themeName'>Питання</DetailModalLabel>
                <DetailModalInput
                  id='themeName'
                  name='themeName'
                  placeholder='Введіть питання'
                  value={themeName}
                  onChange={(e) => { setThemeName(e.target.value); }}
                />
             
            
            </DetailModalItem>
            <DetailModalItem>
              <DetailModalLabel htmlFor='themeDesc'>Відповідь на питання</DetailModalLabel>
              <DetailModalText
                id='themeDesc'
                name='themeDesc'
                placeholder='Введіть відповідь на питання'
                value={themeDesc}
                onChange={(e) => { setThemeDesc(e.target.value); }}
              />
            </DetailModalItem>
          </DetailModalBox>

          <ButtonWrap>
            <CancelButton onClick={onClose}>Скасувати</CancelButton>
            <SaveButton onClick={handleSave} disabled={isDisabled}>Зберегти</SaveButton>
          </ButtonWrap>
        </>
      ) : (
        <Box>
          <Img src={Image} alt='Вихід' />
          <Title>{title}</Title>
          <Text>Ви впевнені, що хочете видалити питання?</Text>
          <WrapButton>
            <NoButton onClick={onClose}>Ні</NoButton>
            <YesButton
              onClick={() => { deleteFaqCategory(themeItem.id); onClose(); }}
            >Так, видалити</YesButton>
          </WrapButton>
        </Box>
      )}
    </Modal>
  );
};

export default AddFaqItem;