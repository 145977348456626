import React from 'react';


import { Box, Img, Label, NoButton, Select, Text, Title, WrapButton, YesButton } from './ExitAccount.styled';

import Image from '../../../../images/exit.png'
import { useDispatch } from 'react-redux';
import { logOut } from '../../../../redux/auth/operations';

const DeleteAccount = ({ onClose }) => {
  const dispatch = useDispatch();

  return (
    <Box>
      <Img src={Image} alt="Вихід" />
      <Title>Вийти з акаунту</Title>
      <Text>Ви впевнені, що хочете вийти з акаунту? Після виходу Вам потрібно буде повторно увійти, щоб продовжити користуватися сервісом.</Text>
      <WrapButton>
        <NoButton onClick={onClose}>Ні, залишитись</NoButton>
        <YesButton
          onClick={() => {
            dispatch(logOut());
            document.body.style.overflow = 'auto';
          }}>Так, вийти</YesButton>

      </WrapButton>
    </Box>
  );
};

export default DeleteAccount;