import styled from '@emotion/styled';
import { ReactComponent as ArrowDown } from '../../../images/arrow/down.svg';


export const ButtonWrap = styled.div`

display: flex;
align-items: center;
justify-content: space-between;

width:100%;


`;

export const ModalTitle = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
text-align: center;
color: var(--colors-grey-900);
width:100%;
text-align:center;
margin-bottom: 24px;
`;


export const CancelButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
width:calc((100% - 12px) / 2);

display: flex;
align-items: center;
color: var(--colors-primary-600);
background:none;
border: 1px solid var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;
justify-content: center;
cursor: pointer;
`;

export const SendButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);
display: flex;
justify-content: center;
width:calc((100% - 12px) / 2);
align-items: center;
background:${props => props.isDisabled === "false" ? 'var(--colors-primary-600)' : 'var(--colors-grey-200)'};
border-radius: 360px;
padding: 8px 12px;
cursor: pointer;

`;

export const Label = styled.label`
font-family: var(--font-family);
font-weight: 600;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-800);
margin-bottom: 8px;
box-sizing: border-box;
display:inline-block;
margin-bottom: 8px;
`;

export const Label1 = styled.label`

position: relative;
  display: inline-block;
`;

export const IconArrowDown = styled(ArrowDown)`
  width: 14px;
  height: 8px;
     content: '';
    position: absolute;
    top: 30%;
    right: 9px;



    pointer-events: none;
 transform: ${props => props.up === "true" ? 'rotate(180deg)' : 'none'};
  transition: transform 0.3s ease-in-out; 

`



// Стиль для textarea
export const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;

border: 1px solid var(--colors-grey-100);
border-radius: 16px;
padding: 12px 16px;
width: 604px;
height: 142px;
  resize: none;
  outline:none;
  margin-bottom: 8px;
  color: var(--colors-grey-800);
    &::placeholder{
    color: var(--colors-grey-400);
  }
`;

// Стиль для кнопок
export const CountLetter = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
width: 100%;
color: var(--colors-grey-600);
margin-bottom: 24px;
 
  text-align: right;
`;

export const Select = styled.select`
  width: 100%;
   padding-right: 32px; /* Відступ для розміщення стрілки справа */
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
  color: var(--colors-grey-800);
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
background: var(--colors-grey-25);
outline: none;
appearance: none;
position: relative;
margin-bottom: 16px;
&.placeholder{

color: var(--colors-grey-400);
};
option{
color: var(--colors-grey-800);
}
`;