import styled from '@emotion/styled';

import { ReactComponent as InfoIcon } from '../../../images/circle.svg';


import { Link } from 'react-router-dom';



export const QuestBox = styled.li`
border-radius: 16px;
padding: 12px;

height: fit-content;
min-width: 310px;
display: flex;
flex-direction: column;
gap: 4px;
align-items: start;
background: var(--colors-grey-25);

width:100% ;

 


 


`;



export const QuestTitleBox = styled.div`
  display: flex;
align-items: center;
justify-content: space-between;
width:100%;
`;

export const QuestTitleWrap = styled.div`
  display: flex;
align-items: center;
justify-content: start;
gap:12px;
width:100%;
`;
export const QuestImage = styled.img`
border-radius: 360px;
width: 32px;
height: 32px;
object-fit: contain;
`;


export const QuestTitle = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
`;
export const QuestText = styled.p`
font-family: var(--font-family);
font-weight: 400;
font-size: 12px;
line-height: 120%;
color: var(--colors-grey-900);
`;
export const QuestTextT = styled.p`
margin-top: 10px;
font-family: var(--font-family);
font-weight: 900;
font-size: 12px;
line-height: 120%;
color: var(--colors-grey-900);
`;

export const Quest = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-800);
height: fit-content;
text-align: left;
/* overflow-y: auto; */
`;
export const QuestDate = styled.p`
margin-top:auto;
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-500);
`;
