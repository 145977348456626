import Search from "components/main/Search";





const SearchPage = () => {
    return <Search />;
};

export default SearchPage;
