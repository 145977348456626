import { useState, useEffect } from 'react';

import { Containerlogo, Loader, LoaderBar, LoaderBarContainer } from './ImageAnimation2.styled';

const ImageAnimation2 = ({ width }) => {


  return (
    <Containerlogo style={{ width: width }}>

      <Loader>
        <LoaderBarContainer>
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
          <LoaderBar />
        </LoaderBarContainer>
      </Loader>
    </Containerlogo >
  );
};
export default ImageAnimation2;