import ImageAnimation1 from "components/layout/loader/ImageAnimation1";
import { BagsBodyBox, BagsBox, BagsTitle, BagsTitleBox, Icon, NavDesc1, NavDesc, NavElement, NavItem, NavList, NavTitle, NavMod, AddBagButton, IconPlus, PaginationContainer, PrevIcon, PageNumbersContainer, PaginationButton, NextIcon, CommentList } from "./ServicesQuest.styled";
import { useGetFaqQuery, useGetMerchantFeedbacksQuery } from "../../../redux/faq/faqApi";
import { useEffect, useState } from "react";
import ListTypeQuest from "../ListTypeQuest";
import QuestionList from "../QuestionList/QuestionList";
import { useNavigate, useSearchParams } from "react-router-dom";
import ServicesModal from "components/modal/ServicesModal";
import AddFaq from "components/modal/AddFaq/AddFaq";
import { AllBox } from "components/finance/Finance/Finance.styled";
import ServiceNavToolbar from "../ServiceNavToolbar";
import CardQuest from "../CardQuest";








const ServicesQuest = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: faqData, isError, isLoading } = useGetMerchantFeedbacksQuery({page: currentPage});
  const [quests, setQuests] = useState([]);




  useEffect(() => { 
    if (faqData) {console.log('faqData :>> ', faqData);setQuests(faqData.data)} }, [faqData])

    const totalPages = faqData?.pagination?.pageCount || 1;

    const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
      }
    };


  return (
    <>
    <ServiceNavToolbar/>
    <AllBox>
    {isLoading ? (<ImageAnimation1 />) : (
      <BagsBox>
        <BagsTitleBox>
          <BagsTitle>Питання від користувачів</BagsTitle>
        </BagsTitleBox>

        <BagsBodyBox>
        {quests?.length === 0 ? null : <CommentList>
                {quests?.map((quest) => <CardQuest key={quest.id} quest={quest} />)}
              </CommentList>}
        {totalPages > 1 && (
        <PaginationContainer>
          <PrevIcon onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <PageNumbersContainer>
            {Array.from({ length: totalPages }, (_, index) => (
              <PaginationButton
                key={`page-${index + 1}`}
                onClick={() => handlePageChange(index + 1)}
                disabled={index + 1 === currentPage}
              >
                {index + 1}
              </PaginationButton>
            ))}
          </PageNumbersContainer>
          <NextIcon onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </PaginationContainer>
      )}
        </BagsBodyBox>
        
      </BagsBox>)}
      </AllBox>
      </>
      
  );
};

export default ServicesQuest;
