import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';



export const notificationApi = createApi({
  reducerPath: 'notifications',

  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/notifications',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Notifications'],

  endpoints: builder => ({
    getNotifications: builder.query({
      query: ({ page = 1 } = {}) => {

        const params = {
          page
        };
        return {
          url: `?page=${page}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Notifications'],
    }),

    readNotifications: builder.mutation({
      query: (ids) => ({
        url: `/read?ids=${ids}`,
        method: 'POST',

      }),
      invalidatesTags: ['Notifications'],
    }),

  }),
});

export const {
  useGetNotificationsQuery,
  useReadNotificationsMutation
} = notificationApi;
