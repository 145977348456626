import { useParams, useLocation, useNavigate } from 'react-router-dom';


import MainNavToolbar from '../MainNavToolbar';

import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/auth/selectors';
import { BagsBox, FilterBox, LabelR,BagsTitle, BagsTitleBox, Label1, SearchLabel, IconSearch, SearchInput } from '../UsersToolbar/UsersToolbar.styled';
import { BagsBodyBox } from '../ArchiveBags/ArchiveBags.styled';
import CustomSelect from 'components/other/CustomSelect';
import { useState } from 'react';
import { useBlockUserMutation, useDeleteUserMutation, useRestoreUserMutation, useUnblockUserMutation } from '../../../redux/users/usersApi';






const Search = () => {



  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const currentDay = queryParams.get('currentDay');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [searchId, setSearchId] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [blockUser]=useBlockUserMutation();
  const [unBlockUser]=useUnblockUserMutation();
  const [deleteUser]=useDeleteUserMutation();
  const [restoreUser]=useRestoreUserMutation();
  const [selectedValue, setSelectedValue] = useState("Всі користувачі");
  const [selectedFilterSearch, setSelectedFilterSearch] = useState("id");
  const [selectUser, setSelectUser] = useState(null);



  const updateQueryParam = (paramName, paramValue) => {
    const searchParams = new URLSearchParams(location.search);
    if (paramValue) {
      searchParams.set(paramName, paramValue);
    } else {
      searchParams.delete(paramName);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };


  const handleSelectFilterChange = (event) => {
    setSearchId('');
    setSearchName('');
  setSearchEmail(''); 
    setSelectedFilterSearch(event);
  };
  const handleSearchChange = (event) => {

    if (selectedFilterSearch==="id")
    {setSearchId(event.target.value);}
  else if(selectedFilterSearch==="email")
    {setSearchEmail(event.target.value);}
  else
  {setSearchName(event.target.value);}
    setCurrentPage(1); // Скидаємо на першу сторінку при новому пошуку
  };

  return (
    <>
      {/* <MainNavToolbar /> */}

      <MainNavToolbar />
      {/* <AlertNoFunc /> */}
      <BagsBox>
      <BagsTitleBox>
        <BagsTitle>Пошук за параметрами</BagsTitle>
        <FilterBox>
     
          <SearchLabel>
            <IconSearch/>
            <SearchInput
              type="text"
              value={selectedFilterSearch==='id'?searchId:selectedFilterSearch==='email'?searchEmail:searchName}
              onChange={handleSearchChange}
              placeholder={`Пошук по ${selectedFilterSearch}`}
            />
               <LabelR htmlFor="pickupFilter">
            <CustomSelect
            colors={true}
              width={"100px"}
              options={["id", ...["email","назві"]]}
              selectedValue={selectedFilterSearch}
              onChange={handleSelectFilterChange}
            />
          </LabelR>
          </SearchLabel>
       
     
        </FilterBox>
      </BagsTitleBox>

      <BagsBodyBox>
      </BagsBodyBox>
      </BagsBox>
    </>
  );
};

export default Search;
