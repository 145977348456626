import ImageAnimation1 from "components/layout/loader/ImageAnimation1";
import { BagsBodyBox, BagsBox, BagsTitle, BagsTitleBox, Icon, NavDesc1, NavDesc, NavElement, NavItem, NavList, NavTitle, NavMod, AddBagButton, IconPlus } from "./ServicesNav.styled";
import { useGetFaqQuery } from "../../../redux/faq/faqApi";
import { useEffect, useState } from "react";
import ListTypeQuest from "../ListTypeQuest";
import QuestionList from "../QuestionList/QuestionList";
import { useNavigate, useSearchParams } from "react-router-dom";
import ServicesModal from "components/modal/ServicesModal";
import AddFaq from "components/modal/AddFaq/AddFaq";
import { AllBox } from "components/finance/Finance/Finance.styled";
import ServiceNavToolbar from "../ServiceNavToolbar";






const initialTitle = 'Тематика запитання/проблеми'

const ServicesNav = () => {
  const navigate=useNavigate();
  const [searchParams] = useSearchParams();
  const { data: faqData, isError, isLoading } = useGetFaqQuery();
  const [questions, setQuestions] = useState([]);
  const categoryId = searchParams.get('category');
  const [title, setTitle] = useState('')
  const [type, setType] = useState('');
  const [types, setTypes] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  useEffect(() => { if (faqData) console.log('faqData :>> ', faqData); }, [faqData])
  useEffect(() => { console.log('questions :>> ', questions); }, [questions])
  useEffect(() => {
 
    if (categoryId && faqData) {
      console.log('categoryId  :>> ', typeof categoryId);

      const category = faqData.find(({ id }) => id === parseInt(categoryId));
      console.log('category  :>> ', category);
      if (category) {
        console.log('category  :>> ', category);
        setQuestions(category.items);
        setTitle(category.title);
        setType(true);
      }
    } else {
      setQuestions([]);
      setTitle(initialTitle);
      setType(false);
    }
  }, [faqData, searchParams]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    if (isModalOpen2) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen2]);

  const handleCloseModal2 = () => {
    setModalOpen2(false);
    document.body.style.overflow = 'auto';

  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';

  };


  return (
    <>
    <ServiceNavToolbar/>
    <AllBox>
    {isLoading ? (<ImageAnimation1 />) : (
      <BagsBox>
        <BagsTitleBox>
          <BagsTitle>{title}</BagsTitle>
          <AddBagButton  onClick={() =>{setTypes('add'); if(!type)  { setTitle('Додати нову тему'); setModalOpen(true)} else {setTitle('Додати нове питання'); setModalOpen2(true)}}} ><IconPlus /> {!type ?  "Додати тему":"Додати питання"}</AddBagButton>
        </BagsTitleBox>

        <BagsBodyBox>
          {!type ? <ListTypeQuest faqData={faqData} setQuestion={setQuestions} setModalOpen={setModalOpen} setType={setType} setTitle={setTitle} /> : <QuestionList categoryId={categoryId }setModalOpen={setModalOpen2} isModalOpen={isModalOpen2} questions={questions} handleCloseModal={handleCloseModal2} types={types} title={title} setTypes={setTypes} setTitle={setTitle}/>}

        </BagsBodyBox>
        {isModalOpen && (
          <AddFaq onClose={handleCloseModal} title={title} types={types} />
        )}
      </BagsBox>)}
      </AllBox>
      </>
      
  );
};

export default ServicesNav;
