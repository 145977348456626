import styled from '@emotion/styled';
import { ReactComponent as EditIcon } from '../../../images/edit.svg';
import { ReactComponent as TrashIcon } from '../../../images/trash.svg';

export const IconEdit = styled(EditIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  top:10px;
  right: 44px;
fill:var(--colors-grey-400);
cursor: pointer;
transition: all 0.5s ease;
  &:hover{
    fill:var(--colors-primary-600);
  }

`;

export const IconTrash = styled(TrashIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  top:10px;
right: 10px;
cursor: pointer;
`;


export const NavList = styled.ul`
display: flex;
flex-wrap: wrap;
justify-content: center;
gap:8px;
width: 100%;
`;

export const NavItem = styled.li`
width: calc((100% - 8px) / 2);
position: relative;
&:last-child{
  margin-top: 16px;

}
`;


export const NavElement = styled.div`
display: block;
background: var(--colors-grey-25);
border: 1px solid var(--colors-grey-200);
border-radius: 16px;
padding: 16px;
min-height: 100%;
height: fit-content;
width: 100%;

cursor: pointer;
`;



export const Icon = styled.img`
  width: 32px;
  height: 32px;
margin-bottom: 12px;
`;
export const NavTitle = styled.h3`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
margin-bottom: 4px;
`;

export const NavDesc = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-500);
`;

export const NavDesc1 = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
text-align: center;
color: var(--colors-grey-600);
margin-bottom: 8px;
`;

export const NavMod = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-primary-600);
cursor: pointer;
`;